<template>
  <div>
    <div v-for="block in menuItems" :key="block.title" class="mb-4">
      <!-- <h5 class="ml-1 mb-2" v-bind:class="darkModeText">{{block.title}}</h5> -->
      <main-title :text="block.title"></main-title>
      <ul class="list-group">
        <router-link tag="li" v-for="item in block.items" :key="item.title" :to="`/account/${item.link}`" class="list-group-item pt-3 pb-3" v-bind:class="darkMode">
          <div class="row">
            <div class="col-10">
              <i v-if="settings.showIcon" class="mr-2 fa" v-bind:class="item.icon"></i>
              {{item.title}}
            </div>
            <div class="col d-flex justify-content-end align-items-center">
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { darkModeStyle } from '../../assets/js/darkMode';

export default {
  data() {
    return {
      settings: {
        showIcon: true
      },
      menuItems: [
        {
          title: 'Account Settings',
          items: {
            myAccount: {
              title: 'Account Details',
              icon: 'fa-user',
              link: 'details'
            },
            loginSecurity: {
              title: 'Login & Security',
              icon: 'fa-user-shield',
              link: 'login'
            },
            paymentCards: {
              title: 'Payment Cards',
              icon: 'fa-credit-card',
              link: 'cards'
            },
            yourAddress: {
              title: 'Address Details',
              icon: 'fa-location-arrow',
              link: 'addresses'
            }
          }
        },
        {
          title: 'Orders',
          items: {
            orderHistory: {
              title: 'Order History',
              icon: 'fa-history',
              link: 'orders'
            },
            transations: {
              title: 'Transactions',
              icon: 'fa-exchange-alt',
              link: 'transactions'
            }
          }
        },
        {
          title: 'Help Center',
          items: {
            contactUs: {
              title: 'Contact Us',
              icon: 'fa-envelope',
              link: 'help'
            },
            faq: {
              title: 'FAQ',
              icon: 'fa-question-circle',
              link: 'faq'
            },
            termsAndConditions: {
              title: 'Terms & Conditions',
              icon: 'fa-file-alt',
              link: 'terms'
            }
          }
        },
      ],
      darkMode: darkModeStyle(),
      darkModeText: darkModeStyle('text-light', 'text-dark')
    }
  },
}
</script>